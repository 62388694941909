import moment from "moment";

export const projectName = "Shubham Artesia";
export const serverErrorMessage = "Something Went Wrong!";

export const custTime = "9 AM";

export const selectableTimes = ["9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM"];

export const scheduleConfUrl = "public_api/leads";
export const bookingUrl = "public/bookings/new";
export const userTypeUrl = "public/user_type";
export const enquiryFormUrl = "public/customer";

export const paymentPlans = ["CLP", "EMI Scheme", "EMI+CLP", "Subvention 1:99", "Subvention 10:90"];

export function confCallParams(callParams) {
	let toSend = {};
	toSend["name"] = callParams.custName;
	toSend["email"] = callParams.custEmail;
	toSend["phone"] = callParams.custPhone;
	toSend["date"] = callParams.custDate ? moment(callParams.custDate).format("DD-MM-YYYY") : null;
	toSend["time"] = callParams.custTime;
	toSend["project"] = projectName;
	return toSend;
}

export function santizeBookingParams(bParams) {
	let toSend = {};
	if (bParams.leadNo) {
		toSend["ln"] = bParams.leadNo;
	} else {
		toSend["p"] = projectName;
	}
	toSend["t"] = bParams.typologyName;
	toSend["f"] = bParams.floorName;
	toSend["in"] = bParams.flatName;
	toSend["wi"] = bParams.wingName;
	toSend["pn"] = bParams.selectedPlan;
	toSend["cpuu"] = bParams.cpId;
	return toSend;
}

export const masterJson = {
	Buildings: [
		{
			id: "A1",
			path: "M466 335H416V365H369.5V299H312V322.5H279.5H251.5V335H221V377V465H285.5H312H336.5H373.5H466V335Z",
			config: "3bhk",
			wing: "A",
			fill: "#0499B7",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "A2",
			path: "M222 335.5H194.5V358.5H168.5V284H142V260H55V323H36V373H47V483H106.5V473.5H130H159H222V335.5Z",
			config: "3bhk",
			wing: "A",
			fill: "#0499B8",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "A3",
			path: "M222 185H194.5V162H168.5V236.5H142V260.5H55V197.5H36V147.5V42H47H65H82H168.5V62.5H222V185Z",
			config: "3bhk",
			wing: "A",
			fill: "#0499B9",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "A4",
			path: "M388.5 161.5V209.5H363H332.5V190.5H281V161.5H256.5H239.5H223V140.5V35H234H252H269H355.5H455V161.5H388.5Z",
			config: "3bhk",
			wing: "A",
			fill: "#0499B10",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "b1",
			path: "M752.5 44V75V106.5V135H716.5V169H665V143H592V127V116.5V106.5V94V75V56V23H639H708.5H729H752.5V44Z",
			config: "2bhk",
			wing: "B",
			fill: "#0499B11",
			opacity: "0.01",
			value: "2BHK",
		},
		{
			id: "b2",
			path: "M941.5 160H929V221H863.5H791V162H778V134.5H753V128V117.5V107.5V95V76V57V24H800H869.5V17H941.5V160Z",
			config: "3bhk",
			wing: "B",
			fill: "#0499B12",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "b3",
			path: "M940 332.5H920.5V397.5H904H865.5V460.5H815.5V450H754V364.5H739V332.5H770.5V306.5H790.5V222H837.5H928.5V282H940V332.5Z",
			config: "3bhk",
			wing: "B",
			fill: "#0499B13",
			opacity: "0.01",
			value: "3BHK",
		},
		{
			id: "b4",
			path: "M754.5 362V461.5H614H607H589.5V472H531.5H502H486.5H470V440.5V335H481H499H516H602.5H702V362H754.5Z",
			config: "3bhk",
			wing: "B",
			fill: "#0499B14",
			opacity: "0.01",
			value: "3BHK",
		},
	],
	units: [
		{
			id: "6",
			floor: "6",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "7",
			floor: "7",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "8",
			floor: "8",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "8",
			floor: "8",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "9",
			floor: "9",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "10",
			floor: "10",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "11",
			floor: "11",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "12",
			floor: "12",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "13",
			floor: "13",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "14",
			floor: "14",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "15",
			floor: "15",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "16",
			floor: "16",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "17",
			floor: "17",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "18",
			floor: "18",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "19",
			floor: "19",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "21",
			floor: "21",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "22",
			floor: "22",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "23",
			floor: "23",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "24",
			floor: "24",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "25",
			floor: "25",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "26",
			floor: "26",
			building: "C-2",
			floorPlan: "https://s3.ap-south-1.amazonaws.com/enrichr.public.images/shubham-artesia/floor-plan.png",
			width: "891",
			height: "595",
			paths: [
				{
					id: "C1",
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C2",
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C3",
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
				{
					id: "C4",
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
	],
	Variants: [
		{
			id: "C1",
			key: "C1",
			value: "3bhk",
			wing: "C-2",
			configuration: "3BHK",
			paths: [
				{
					path: "M340 555H423V373.5H284.5V346.5H242.5V332H138V417.12H114.994H83V463.928V555H150.659H178.457H242.5V506H278V521H340V555Z",
					fill: "#0499B7",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "C2",
			key: "C2",
			value: "2bhk",
			wing: "C-2",
			configuration: "2BHK",
			paths: [
				{
					path: "M187 51H142V62V69V79.5V89H103.5V152.5H142H264.5V211.5H336.5H422V166V69H375.841H348.043H341V62V51V27H225V51H187Z",
					fill: "#0499B9",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "C3",
			key: "C3",
			value: "3bhk",
			wing: "C-2",
			configuration: "3BHK",
			paths: [
				{
					path: "M508 69H423V211.5H473.5H497.5H518V267H598H626H653.5V204H719V171H788V74H741.841H714.043H698.5H672.5H653.5V38H508V69Z",
					fill: "#0499B8",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
		{
			id: "C4",
			key: "C4",
			value: "4bhk",
			wing: "C-2",
			configuration: "4BHK",
			paths: [
				{
					path: "M638.5 559.5H809.5V420.5H754.5V341H661.5V358H587.5V292H518.457V373H456.5H423V463.428V554.5H490.659H518.457H567H582.5H603.5V516H638.5V559.5Z",
					fill: "#0499B10",
					opacity: "0.01",
					fillOpacity: "0.3",
				},
			],
		},
	],
};

export const FormSteps = ["Explore Project", "Explore Master layout", "Select Variant", "Check Details", "KYC & Make Payment"];
